<template>
  <div class="proposition-description-wrapper" @click="expand">
    <h2 class="section-title">{{ $t('proposition_description_title') }}</h2>
    <h4 class="subtitle">
      <span v-if="$country.isCountry('se')">
        {{ $t('proposition_description_type') }}
        <b>{{ type }}</b>
      </span>
      <span v-if="isMobile">
        {{ $t('proposition_description_ad_num') }}
        <b>{{ spacedPropositionNumber }}</b>
      </span>
      <span v-if="readableLandlord && !landlordFieldHasContactInfo">
        {{ $t('proposition_description_landlord') }}
        <b>{{ readableLandlord }}</b>
      </span>
      <span v-else-if="readableLandlord && landlordFieldHasContactInfo">
        {{ $t('edit_prop_details_landlord') }}:
      </span>
    </h4>
    <p
      ref="description"
      :class="[
        'proposition-description-content',
        { expanded: isExpanded, truncated: isTruncated }
      ]"
    >
      <template v-if="description && description.length > 0 && !hasContactInfo">
        <span v-for="(line, index) in description.split('\n')" :key="index">
          {{ line }}
          <br />
        </span>
      </template>
      <template v-else>{{ $t('proposition_description_no_desc') }}</template>
    </p>
    <p
      v-if="!isExpanded && description && isTruncated"
      class="proposition-description-expand-button"
    >
      {{ $t('proposition_description_read_more') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formValidations from '../../../utils/formValidations';
import i18n, { routeLocale } from '@/i18n';

export default {
  name: 'PropositionDescription',

  props: {
    description: {
      type: String,
      default: ''
    },
    propositionNo: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    landlord: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isExpanded: window.isPrerendered === true,
      descriptionHeight: 0
    };
  },
  computed: {
    ...mapGetters({ isMobile: 'screenSize/isMobile' }),

    isTruncated: function () {
      if (this.description) {
        return this.descriptionHeight > 600 && !this.isExpanded;
      }

      return false;
    },

    landlordFieldHasContactInfo() {
      return formValidations.residenceDescription(this.readableLandlord);
    },

    hasContactInfo() {
      return formValidations.residenceDescription(this.description);
    },

    spacedPropositionNumber() {
      return this.propositionNo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
    },

    readableLandlord() {
      if (!this.landlord) return '';

      const lowerCaseLandlord = this.landlord.trim().toLowerCase();

      switch (lowerCaseLandlord) {
        case 'privat':
          return i18n.t('proposition_private_landlord', routeLocale);
        default:
          return this.landlord.trim();
      }
    }
  },

  mounted() {
    this.setDescriptionHeight();
  },
  methods: {
    expand() {
      this.isExpanded = true;
    },

    setDescriptionHeight() {
      this.descriptionHeight = this.$refs.description.clientHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.proposition-description-content {
  width: 100%;
  line-height: 1.8;
  font-size: 0.95rem;
  color: $text-primary;

  &.truncated {
    height: 500px;
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 200px;
      width: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }
  }

  &.expanded {
    overflow: auto;
    height: auto;
  }
}

.proposition-description-expand-button {
  font-weight: 600;
  font-size: 0.95rem;
  color: $transfer-blue;
  margin-top: 35px;
  cursor: pointer;
}

.section-title {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 20px 0 16px 0;
}

.subtitle {
  font-size: 0.95rem;
  margin-top: 10px;
  font-weight: 400;
  color: $text-primary;

  @media ($mobile) {
    margin-bottom: 30px;
  }
}

.subtitle span {
  margin-right: 20px;
  @media ($mobile) {
    font-size: 0.95rem;
    display: block;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}

.subtitle span b {
  @media ($mobile) {
    text-align: right;
    display: inline-block;
    float: right;
    font-weight: 600;
  }
}
</style>

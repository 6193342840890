import * as cloneDeep from 'lodash/cloneDeep';
import services from './services';
import {
  SORT_TYPES,
  INITIAL_FILTER,
  FILTER_SETUP,
  SORT_DIRECTIONS,
  SWAP_FILTER_TYPES,
  FETCH_MODE
} from './index';
import { router } from '@/main';

const noInterestTimeouts = {};

function getLegacySortTypeName(sortType) {
  switch (sortType) {
    case SORT_TYPES.HIGHEST_SCORE:
      return 'score';
    case SORT_TYPES.SQM_ASC:
      return 'sqm';
    case SORT_TYPES.SQM_DESC:
      return 'sqm';
    case SORT_TYPES.FLOOR:
      return 'floor';
    case SORT_TYPES.ROOMS_ASC:
      return 'rooms';
    case SORT_TYPES.ROOMS_DESC:
      return 'rooms';
    case SORT_TYPES.RENT_ASC:
      return 'rent';
    case SORT_TYPES.RENT_DESC:
      return 'rent';
    case SORT_TYPES.INTEREST_DATE:
      return 'interest';
    case SORT_TYPES.POSTAL_AREA:
      return 'postalArea';
    case SORT_TYPES.PERCENTAGE:
      return 'percentage';
    case SORT_TYPES.SEEN_AT:
      return 'seenAt';
    case SORT_TYPES.ADDRESS:
      return 'address';
    case SORT_TYPES.TYPE:
      return 'type';
    default:
      return '';
  }
}

// Avoid cutting of extremes by setting max to ridiculus number in request
function handleMaxValues(filter) {
  if (filter?.rent?.max >= FILTER_SETUP.rent.max) {
    filter.rent.max = 100000000;
  }
  if (filter?.rooms?.max >= FILTER_SETUP.rooms.max) {
    filter.rooms.max = 100000000;
  }
  if (filter?.floor?.max >= FILTER_SETUP.floor.max) {
    filter.floor.max = 100000000;
  }
  if (filter?.sqm?.max >= FILTER_SETUP.sqm.max) {
    filter.sqm.max = 100000000;
  }
}

function resetFilters(context, { onPageLoad = false }) {
  context.commit('updateFilter', {
    filter: INITIAL_FILTER,
    clearSearchTerm: true
  });

  context.dispatch('setSwapType', {
    swapFilterType: SWAP_FILTER_TYPES.UNANSWERED,
    onPageLoad
  });
}

function setSwapType(
  context,
  { swapFilterType, update = false, onPageLoad = false }
) {
  // Get swap filter type from query and set if exists
  let swapFilterTypeQuery = onPageLoad
    ? new URL(location.href).searchParams.get('filterType')
    : null;

  if (swapFilterTypeQuery) {
    swapFilterType = swapFilterTypeQuery.toUpperCase();
  }

  context.commit('setSwapFilterType', { swapFilterType });

  const currentFilter = cloneDeep(context.state.filter);

  const defaultSort = context.getters['defaultSort'];
  context.commit('setSortType', { type: defaultSort });

  switch (swapFilterType) {
    case SWAP_FILTER_TYPES.ALL:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMarked: undefined,
          percentage: undefined,
          isNew: undefined,
          isUnanswered: undefined
        },
        clearSearchTerm: false // if is map
      });
      break;

    case SWAP_FILTER_TYPES.FULL_INTEREST:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMarked: undefined,
          percentage: {
            min: 100,
            max: 100
          },
          isNew: undefined,
          isUnanswered: undefined
        },
        sortBy: { key: 'interest', descending: true },
        clearSearchTerm: false
      });
      break;

    case SWAP_FILTER_TYPES.SWAPLIST_MARKED:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMarked: undefined,
          percentage: undefined,
          isNew: undefined,
          isUnanswered: false,
          isFavourite: undefined,
          type: undefined
        },
        clearSearchTerm: false
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.LAST_PART_NEW:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMarked: undefined,
          percentage: undefined,
          isNew: undefined,
          isUnanswered: undefined,
          isFavourite: undefined,
          isLastPart: true
        },
        clearSearchTerm: false
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.UNANSWERED:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMarked: undefined,
          percentage: undefined,
          isNew: undefined,
          isUnanswered: true,
          isFavourite: undefined,
          isLastPart: undefined
        },
        clearSearchTerm: false
      });
      break;

    case SWAP_FILTER_TYPES.ANSWERED:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMarked: true,
          isUnanswered: undefined,
          isNew: undefined,
          type: undefined,
          isLastPart: undefined
        },
        clearSearchTerm: false
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.REMOVED:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMarked: false,
          isUnanswered: undefined,
          isNew: undefined,
          type: undefined,
          isLastPart: undefined
        },
        clearSearchTerm: false
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.UNANSWERED_NEW:
      context.commit('updateFilter', {
        ...currentFilter,
        filter: {
          type: SWAP_FILTER_TYPES.UNANSWERED_NEW
        },
        clearSearchTerm: true
      });
      break;

    case SWAP_FILTER_TYPES.NEW:
      context.commit('updateFilter', {
        filter: {
          ...currentFilter,
          interestMark: undefined,
          percentage: {
            min: 0,
            max: 70
          },
          isNew: true,
          isUnanswered: undefined
        },
        clearSearchTerm: false
      });
      break;

    case SWAP_FILTER_TYPES.ONLY_FULL_INTEREST:
      context.commit('updateFilter', {
        ...currentFilter,
        filter: {
          type: SWAP_FILTER_TYPES.ONLY_FULL_INTEREST
        },
        clearSearchTerm: true
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.INTEREST_MARKED:
      context.commit('updateFilter', {
        ...currentFilter,
        filter: {
          type: SWAP_FILTER_TYPES.INTEREST_MARKED
        },
        clearSearchTerm: true
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.MARKED:
      context.commit('updateFilter', {
        ...currentFilter,
        filter: {
          type: SWAP_FILTER_TYPES.MARKED
        },
        clearSearchTerm: true
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.LAST_PART:
      context.commit('updateFilter', {
        ...currentFilter,
        filter: {
          type: SWAP_FILTER_TYPES.LAST_PART
        },
        clearSearchTerm: true
      });
      context.commit('setSortType', { type: SORT_TYPES.INTEREST_DATE });
      break;

    case SWAP_FILTER_TYPES.FAVOURITES:
      context.commit('updateFilter', {
        filter: {
          percentage: {
            min: 0,
            max: 100
          },
          isFavourite: true
        },
        clearSearchTerm: true
      });
      break;

    default:
      context.dispatch('resetFilters');
  }

  if (update) {
    context.dispatch('getSwaps');
  }

  if (!onPageLoad) {
    router
      .replace({
        query: { filterType: swapFilterType.toLowerCase() }
      })
      .catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          console.error(error);
        }
      });
  }
}

function getSwapInterestCounts(context, propositionId) {
  const id = propositionId || context.getters['currentPropositionId'];
  if (!id) {
    return;
  }

  services.fetchInterestCounts(id).then(data => {
    context.commit('setSwapInterestCounts', data);
  });
}

async function getSwaps(
  context,
  { page, propositionId, mode, pageSize } = {
    propositionId: undefined,
    mode: FETCH_MODE.REPLACE,
    pageSize: null
  }
) {
  const { state } = context;

  const infiniteScrollPaging =
    state.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW;

  context.commit('setLoading', { loading: true });

  const filter = cloneDeep(state.filter);
  const { sortDirection, searchTerm } = state;
  const savedPageSize = localStorage.getItem('swap-list_page-size') || false;

  handleMaxValues(filter);

  const selectedPage = page !== undefined ? page : infiniteScrollPaging ? 0 : 1;
  const fallbackPageSize = infiniteScrollPaging ? 12 : 25;

  const selectedPageSize =
    pageSize ??
    (savedPageSize && !infiniteScrollPaging
      ? parseInt(savedPageSize)
      : fallbackPageSize);

  const filterObj = {
    propositionId: propositionId || context.getters['currentPropositionId'],
    page: selectedPage,
    pageSize: selectedPageSize,
    filter: { ...filter, searchTerm: searchTerm || undefined },
    skipInterestCounts: true,
    sortBy: {
      key: getLegacySortTypeName(state.sortType),
      descending: sortDirection === SORT_DIRECTIONS.DESCENDING ? true : false
    }
  };

  // save filter count for current request

  let filterCount = 0;
  const currentFilter = state.filter;

  const excludedFilters = [
    'isNew',
    'percentage',
    'isUnanswered',
    'interestMarked',
    'isLastPart'
  ];

  Object.keys(currentFilter).forEach(key => {
    if (
      JSON.stringify(currentFilter[key]) !==
        JSON.stringify(INITIAL_FILTER[key]) &&
      !excludedFilters.includes(key)
    ) {
      filterCount++;
    }
  });
  const res = await services
    .fetchSwaps({ filterObj, propositionId, page: selectedPage })
    .catch(() => context.commit('setLoading', { loading: false }));

  if (!res) {
    context.commit('setLoading', { loading: false });
    return;
  }

  const swapData = {
    address: res.address,
    swaps: res.swaps || [],
    currentPage: res.page,
    totalPages: res.totalPages,
    unmarkedSwaps: res.unmarkedSwaps,
    fullInterestSwaps: res.fullInterestSwaps,
    newSwaps: res.newSwaps || 0,
    undistributed: res.undistributed || 0,
    filterCount,
    areaGroups: res.areaGroups,
    preFilterCountTotal: res.preFilterCount?.total || 0,
    preFilterCountUnmarked: res.preFilterCount?.unmarked || 0,
    postFilterCountTotal: res.postFilterCount?.total || 0,
    postFilterCountWithDiffs: res.postFilterCount?.total || 0,
    postFilterCountUnmarked: res.postFilterCount?.unmarked || 0,
    listPropositionId: res.listPropositionId
      ? res.listPropositionId.toString()
      : '',
    queueCount: res.queueCount,
    hasMore: selectedPage < res.totalPages,
    unansweredSwaps: res?.swapInterestCounts.unanswered.total || 0,
    answeredSwaps: res?.swapInterestCounts.answered.total || 0,
    removedSwaps: res?.swapInterestCounts.removed.total || 0
  };

  const isFirstPage = infiniteScrollPaging
    ? selectedPage === 0
    : selectedPage === 1;

  if (mode !== FETCH_MODE.MERGE || isFirstPage) {
    context.commit('setSwapData', swapData);
  } else {
    context.commit('mergeSwapData', swapData);
  }
  context.commit('setLoading', { loading: false });

  if (swapData.preFilterCountTotal === swapData.postFilterCountTotal) {
    context.dispatch('gtm/loadSwapList', swapData, { root: true });
  }

  if (
    filterObj.filter.type === SWAP_FILTER_TYPES.UNANSWERED ||
    filterObj.filter.type === SWAP_FILTER_TYPES.ONLY_FULL_INTEREST ||
    filterObj.filter.type === SWAP_FILTER_TYPES.INTEREST_MARKED ||
    filterObj.filter.type === SWAP_FILTER_TYPES.MARKED ||
    filterObj.filter.type === SWAP_FILTER_TYPES.LAST_PART ||
    filterObj.filter.isFavourite
  ) {
    context.dispatch('swapList/getSwapInterestCounts', null, { root: true });
  }
}

function selectPage(context, { page, mode }) {
  const pid = context.getters['currentPropositionId'];
  context.dispatch('getSwaps', {
    page,
    propositionId: pid,
    mode
  });
}

async function fetchSwapDetails(
  context,
  { type, propositionId, propositionId2 }
) {
  const userPropositionId = context.getters['currentPropositionId'];

  let swapDetails;
  if (type === 'triangleswap') {
    swapDetails = await services.getTriangleSwapDetails({
      userPropositionId,
      propositionId,
      propositionId2
    });
  } else {
    swapDetails = await services.getDirectSwapDetails({
      userPropositionId,
      propositionId
    });
  }

  context.commit('setSwapDetails', { swapDetails });

  if (type === 'triangleswap') {
    context.dispatch('fetchSwapAlternatives', {
      userPropositionId,
      propositionId
    });
  }
}

function selectThirdParty(context, { alternative }) {
  context.commit('replaceThirdParty', { alternative });
}

async function fetchSwapAlternatives(context, { propositionId, page = 1 }) {
  context.commit('setSwapAlternativesLoading');

  const { filter } = context.state;

  const { showDenied } = filter;

  const isMobile = context.rootGetters['screenSize/isMobile'];

  const userPropositionId = context.getters['currentPropositionId'];

  const { alternatives, totalPages } =
    await services.getTriangleSwapAlternatives({
      userPropositionId,
      propositionId,
      page,
      showDenied,
      pageSize: isMobile ? 20 : 7
    });

  context.commit('setSwapAlternatives', {
    alternatives,
    currentPage: page,
    totalPages
  });
}

async function blockSwapParty(context, { propositionId, blocked }) {
  if (blocked) {
    context.commit('removeThirdPartyFromDetails');
  }

  await services.blockSwapParty({ propositionId, blocked });
}

async function fetchBlockedPropositions(ctx, propositionId) {
  return services.fetchBlockedPropositions(propositionId);
}

function closeSwapDetails(context) {
  context.commit('closeSwapDetails');
}

function setSwapListType(context, { type }) {
  context.commit('setSwapListType', { type });
}

function setSortType(context, { type, sortDirection }) {
  context.commit('setSortType', { type });

  context.commit('setSortDirection', {
    sortDirection
  });

  const pid = context.getters['currentPropositionId'];

  context.dispatch('getSwaps', {
    propositionId: pid
  });
}

function setSelectedSortType(context, { type }) {
  context.commit('setSortType', { type });
}

function setCurrentSwapViewTab(context, { type }) {
  context.commit('setCurrentSwapListTab', { type });
}

function favouriteSocketUpdate(context, { targetPropositionId, favourite }) {
  context.commit('updateFavouriteStatus', { targetPropositionId, favourite });
}

function toggleCheckboxFilter(context, { key }) {
  context.commit('toggleCheckboxFilter', { key });
}
function updateRangeFilter(context, { key, min, max }) {
  const newFilter = cloneDeep(context.state.filter);

  if (!newFilter[key]) newFilter[key] = {};
  newFilter[key].min = min;
  newFilter[key].max = max;

  context.commit('updateFilter', { filter: newFilter });
}

function setSwapInterest(context, { targetPropositionId, interested }) {
  context.commit('setSwapInterest', { targetPropositionId, interested });
}

function toggleSwapItemMode(context, { mode }) {
  context.commit('toggleSwapItemMode', { mode });
  localStorage.setItem('SWAP_ITEM_TYPE', mode);
  const pid = context.getters['currentPropositionId'];

  context.dispatch('getSwaps', { propositionId: pid });
}

function listSetSortDirectionAndSort(context, { sortDirection, sortType }) {
  context.commit('setSortDirection', { sortDirection });
  context.dispatch('setSortType', { type: sortType });
}

function toggleSortDirection(context) {
  const { sortDirection } = context.state;

  if (sortDirection === SORT_DIRECTIONS.DESCENDING) {
    context.commit('setSortDirection', {
      sortDirection: SORT_DIRECTIONS.ASCENDING
    });
  } else {
    context.commit('setSortDirection', {
      sortDirection: SORT_DIRECTIONS.DESCENDING
    });
  }

  const pid = context.getters['currentPropositionId'];

  context.dispatch('getSwaps', {
    propositionId: pid
  });
}

function handleUpdateSearchTerm(context, { value }) {
  context.commit('setSearchTerm', { value });
}

function performSearchTermSwapSearch(context) {
  const pid = context.getters['currentPropositionId'];
  context.dispatch('getSwaps', {
    propositionId: pid
  });
}

function handleUpdateFilterAreas(context, { areaName }) {
  context.commit('toggleFilterArea', { areaName });
}

async function markPropositionForNoInterest(
  { commit, dispatch, state },
  { targetPropositionId, interested, tutorialStarted }
) {
  const stringifiedPropositionId = targetPropositionId.toString();

  const alreadyMarked = state.noInterestMarkedIds.includes(
    stringifiedPropositionId
  );

  if (interested === false) {
    if (alreadyMarked && state.swapFilterType !== SWAP_FILTER_TYPES.REMOVED) {
      commit('unmarkPropositionNoInterest', stringifiedPropositionId);

      const idInReverse = state.reverseInterestMarkedIds.some(
        id => id.propositionId == targetPropositionId
      );

      if (!idInReverse) {
        commit('markPropositionReverseInterest', stringifiedPropositionId);
        commit('unmarkPropositionNoInterest', stringifiedPropositionId);
      }
    } else {
      if (state.swapFilterType !== SWAP_FILTER_TYPES.REMOVED) {
        commit('markPropositionNoInterest', stringifiedPropositionId);

        if (state.swapItemMode === 'CARDS') {
          const timeOutDuration = tutorialStarted ? 500 : 4000;
          noInterestTimeouts[stringifiedPropositionId] = setTimeout(() => {
            if (!state.noInterestMarkedIds.includes(stringifiedPropositionId)) {
              return;
            }

            commit('removeSwapFromArray', targetPropositionId);

            dispatch(
              'interests/markInterest',
              {
                targetPropositionId,
                interested: false,
                where: 'swap-list'
              },
              { root: true }
            );
          }, timeOutDuration);
        } else {
          if (!state.noInterestMarkedIds.includes(stringifiedPropositionId)) {
            return;
          }

          commit('removeSwapFromArray', targetPropositionId);

          dispatch(
            'interests/markInterest',
            {
              targetPropositionId,
              interested: false,
              where: 'swap-list'
            },
            { root: true }
          );
        }
      }
    }
  } else {
    commit('unmarkPropositionNoInterest', stringifiedPropositionId);
  }
}

function setNewSwapsCount(context, count) {
  context.commit('setNewSwapsCount', count);
}

function setUnansweredCount(context, count) {
  context.commit('setUnansweredCount', count);
}

function showSwapAddedBadge(context, val) {
  // Hide first to retrigger animation
  context.commit('showSwapAddedBadge', false);
  setTimeout(() => {
    context.commit('showSwapAddedBadge', val);
  }, 1);
}

function setUnmarkPropositionNoInterest(context, propositionId) {
  if (noInterestTimeouts[propositionId]) {
    clearTimeout(noInterestTimeouts[propositionId]);
  }
  context.commit('unmarkPropositionNoInterest', propositionId);
}

function setSelectedFilters(context, value) {
  context.commit('setSelectedFilters', value);
}

function removeSelectedFilter(context, value) {
  context.commit('removeSelectedFilter', value);
}

function clearSelectedFilters(context) {
  context.commit('clearSelectedFilters');
}

export default {
  resetFilters,
  getSwaps,
  setSwapListType,
  setUnansweredCount,
  setSortType,
  setSelectedSortType,
  closeSwapDetails,
  setCurrentSwapViewTab,
  selectThirdParty,
  fetchSwapDetails,
  fetchSwapAlternatives,
  blockSwapParty,
  selectPage,
  favouriteSocketUpdate,
  toggleCheckboxFilter,
  updateRangeFilter,
  setSwapInterest,
  toggleSwapItemMode,
  toggleSortDirection,
  listSetSortDirectionAndSort,
  handleUpdateSearchTerm,
  performSearchTermSwapSearch,
  setSwapType,
  handleUpdateFilterAreas,
  markPropositionForNoInterest,
  setNewSwapsCount,
  showSwapAddedBadge,
  getSwapInterestCounts,
  fetchBlockedPropositions,
  setUnmarkPropositionNoInterest,
  setSelectedFilters,
  removeSelectedFilter,
  clearSelectedFilters
};

<template>
  <transition-group name="swap-list" tag="div" class="swaps-container">
    <div
      v-for="(swap, index) in swapList"
      :key="swap.propositionId + swap.details.type"
      :ref="swap.propositionId + swap.details.type"
      :class="'swap-items-wrapper'"
      :style="{
        'min-height':
          (shouldShowReverseComponent &&
            isFirstPropositionNotInterested(swap, index) &&
            isPropositionNotInterested(swap.propositionId.toString())) ||
          showContextualTutorial
            ? dynamicHeight
            : ''
      }"
    >
      <div
        v-if="index === 0"
        :hidden="
          tutActiveStep === null ||
          tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
        "
        :class="
          shouldRevealProgressBar && showContextualTutorial
            ? 'reveal-progress'
            : ''
        "
      >
        <div
          v-outside-click="handleClickOutsideProgress"
          class="progress-bar-wrapper"
          :style="{
            visibility: !isShowingSwapModal ? 'visible' : 'hidden',
            opacity: !isShowingSwapModal ? 1 : 0
          }"
        >
          <ProgressBar ref="progressBar" :percentage="progressBar" />

          <transition
            v-if="shouldRevealProgressBar && showContextualTutorial"
            name="tooltip-slide-up"
            appear
          >
            <div class="tooltip-container">
              <BaseTooltip @callback="handleTooltipCallback">{{
                $t('tutorial_contextual_continue_interest')
              }}</BaseTooltip>
              <BaseButton
                v-if="showTooltipButton"
                class="smaller next-button"
                @click="handleClickOutsideProgress"
              >
                <slot name="button">
                  {{ $t('tutorial_contextual_button_next') }}
                </slot>
              </BaseButton>
            </div>
          </transition>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <component
          :is="currentSwapItemComponent"
          v-if="
            !isMobile &&
            swap.type !== 'sponsoredPropositions' &&
            !noInterestMarkedIds.includes(swap.propositionId.toString())
          "
          :key="swap.propositionId + swap.details.type"
          :show-tutorial-pointer="tutorialStarted && index === 0"
          :class="shouldRevealSwap && index === 0 ? 'reveal' : ''"
          :show-swap-details="handleShowSwapDetails"
          :swap="swap"
          :disable-link="shouldDisableLink"
          :toned="index % 2 !== 0"
          :index="index"
          :blur-content="
            (primaryPropositionIsLimited &&
              swapFilterType === 'LAST_PART_NEW') ||
            swap.isBlurred
          "
          :is-blurred-distribution="
            shouldBlurLastSwaps &&
            (index === swaps.length || index === swaps.length - 1)
          "
          :reverse-is-shown="reverseIsShown"
          @no-interest="handleNoInterest(swap.propositionId)"
        />
        <BasePropositionCardNew
          v-if="
            isMobile &&
            swap.type !== 'sponsoredPropositions' &&
            !isPropositionNotInterested(swap.propositionId.toString())
          "
          :id="swap.propositionId + swap.details.type"
          :key="swap.propositionId + swap.details.type"
          :show-additional-images="!tutorialStarted"
          :show-tutorial-pointer="tutorialStarted && index === 0"
          :class="shouldRevealSwap && index === 0 ? 'reveal' : ''"
          :swap="swap"
          :disable-link="shouldDisableLink"
          :index="index"
          :is-blurred="
            (primaryPropositionIsLimited &&
              swapFilterType === 'LAST_PART_NEW') ||
            swap.isBlurred
          "
          :show-interest-buttons-block="
            !(
              (primaryPropositionIsLimited &&
                swapFilterType === 'LAST_PART_NEW') ||
              swap.isBlurred
            )
          "
          :is-blurred-distribution="
            shouldBlurLastSwaps &&
            (index === swaps.length || index === swaps.length - 1)
          "
          :reverse-interest-mark-clicked="reverseNoInterestMark"
          :reverse-is-shown="reverseIsShown"
          @no-interest="handleNoInterest(swap.propositionId)"
        />
        <div
          v-else-if="
            shouldShowReverseComponent &&
            isFirstPropositionNotInterested(swap, index) &&
            isPropositionNotInterested(swap.propositionId.toString()) &&
            !tutorialStarted
          "
          :key="swap.propositionId + swap.details.type + 'reverse'"
        >
          <BaseReverseActionPropositionCard
            :swap="swap"
            @reverse-no-interest-mark="
              handleReverseNoInterestMark(swap.propositionId)
            "
          />
        </div>
      </transition>
      <SponsoredPropositions
        v-if="
          $country.isFeatureEnabled('EXTRA_VISIBILITY') &&
          sponsoredPropositions.length &&
          swaps.length > 15 &&
          swap.type === 'sponsoredPropositions'
        "
        :propositions="sponsoredPropositions"
        :title="$t('proposition_extra_visibility_title')"
        class="sponsored-propositions"
      />
    </div>
  </transition-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import outsideClick from '@/directives/outside-click';
import {
  SORT_TYPES,
  SWAP_ITEM_TYPES,
  SWAP_FILTER_TYPES
} from '../../store/modules/swapList';
import INTEREST_STATUSES from '../../store/modules/interests';
import SwapItem from './SwapItem';
import CompressedSwapItem from './CompressedSwapItem';
import StandardModal from '@/components/Modals/Standard';
import SponsoredPropositions from '@/components/SponsoredPropositions/SponsoredPropositions';
import ProgressBar from '@/components/ContextualTutorial/ProgressBar';
import BaseTooltip from '../BaseTooltip.vue';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '../../store/modules/tutorial';
import BaseReverseActionPropositionCard from '@/components/BaseReverseActionPropositionCard';
import routesConstants from '../../router/routes.constants';

export default {
  name: 'SwapContainer',

  components: {
    BaseTooltip,
    SwapItem,
    StandardModal,
    CompressedSwapItem,
    SponsoredPropositions,
    ProgressBar,
    BaseReverseActionPropositionCard
  },

  directives: {
    outsideClick
  },

  props: {
    usedIn: {
      type: String,
      default: null
    },

    shouldBlurLastSwaps: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      sponsoredPosition: 11,
      SORT_TYPES,
      SWAP_ITEM_TYPES,
      SWAP_FILTER_TYPES,
      extraVisModalOpen: false,
      SwapItem,
      CompressedSwapItem,
      highlightProgress: false,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES,
      INTEREST_STATUSES,
      allowClickOutside: false,
      tooltipTimeout: null,
      tooltipTimeoutMs: 5500,
      showTooltipButton: false,
      isShowingSwapModal: false,
      reverseNoInterestMark: false,
      propositionId: null,
      dynamicHeight: null,
      reverseIsShown: false
    };
  },

  computed: {
    ...mapGetters({
      swaps: 'swapList/swaps',
      isMobile: 'screenSize/isMobile',
      sponsoredPropositions: 'propositions/sponsoredPropositions',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited',
      userPropositions: 'myPropositions/propositions',
      swapItemMode: 'swapList/swapItemMode',
      swapFilterType: 'swapList/swapFilterType',
      showContextualTutorial: 'tutorial/showContextualTutorial',
      tutInterestMarks: 'tutorial/tutInterestMarks',
      tutActiveStep: 'tutorial/activeStep',
      progressBar: 'tutorial/progressBar',
      tutStatus: 'tutorial/status',
      showGlobalModal: 'ui/showGlobalModal',
      reverseInterestMarkedIds: 'swapList/reverseInterestMarkedIds',
      noInterestMarkedIds: 'swapList/noInterestMarkedIds'
    }),

    shouldShowReverseComponent() {
      return (
        (this.$route.path === routesConstants.SWAP_LIST &&
          this.swapFilterType !== SWAP_FILTER_TYPES.REMOVED) ||
        this.swapFilterType === SWAP_FILTER_TYPES.INTEREST_MARKED ||
        this.swapFilterType === SWAP_FILTER_TYPES.FAVOURITES ||
        this.swapFilterType === SWAP_FILTER_TYPES.ONLY_FULL_INTEREST ||
        this.swapFilterType === SWAP_FILTER_TYPES.LAST_PART ||
        this.swapFilterType === SWAP_FILTER_TYPES.LAST_PART_NEW
      );
    },

    shouldRevealProgressBar() {
      return this.tutActiveStep === TUTORIAL_STEPS_TYPES.CONTINUE_INTEREST;
    },

    shouldDisableLink() {
      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP
      );
    },

    tutorialStarted() {
      return this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED;
    },

    tutorialCompleted() {
      return this.tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED;
    },

    shouldRevealSwap() {
      if (this.showGlobalModal) {
        return false;
      }

      return (
        this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        this.tutActiveStep === TUTORIAL_STEPS_TYPES.HIGHLIGHT_SWAP
      );
    },

    currentSwapItemComponent() {
      const {
        isMobile,
        SwapItem,
        CompressedSwapItem,
        swapItemMode,
        SWAP_ITEM_TYPES
      } = this;

      if (!isMobile) return SwapItem;

      if (swapItemMode === SWAP_ITEM_TYPES.CARDS) {
        return SwapItem;
      }

      return CompressedSwapItem;
    },

    primaryPropositionWithDetails() {
      const { primaryProposition, userPropositions } = this;

      return userPropositions.find(
        p => p.propositionId === primaryProposition.propositionId
      );
    },

    swapList() {
      const list = [...this.swaps];
      list.splice(this.sponsoredPosition, 0, {
        type: 'sponsoredPropositions',
        propositionId: '123',
        details: {
          type: 'sponsoredPropositions',
          swapScore: 0
        }
      });
      return list;
    },

    firstAdditionalSwapIndex() {
      return this.swapList.findIndex(swap => !!swap.diff);
    },

    setToTrueAfterReverseClick() {
      return (
        this.swapFilterType === SWAP_FILTER_TYPES.ANSWERED ||
        this.swapFilterType === SWAP_FILTER_TYPES.INTEREST_MARKED ||
        this.swapFilterType === SWAP_FILTER_TYPES.ONLY_FULL_INTEREST
      );
    }
  },

  watch: {
    $route(newRoute) {
      if (newRoute.query) {
        const queryParams = Object.keys(newRoute.query);
        this.isShowingSwapModal = queryParams.includes('propositionPageId');
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.updateAllCardHeights();

    if (this.$route.query) {
      const queryParams = Object.keys(this.$route.query);
      this.isShowingSwapModal = queryParams.includes('propositionPageId');
    }
    if (
      !this.sponsoredPropositions ||
      this.sponsoredPropositions.length === 0
    ) {
      this.getSponsoredPropositions();
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
    clearTimeout(this.tooltipTimeout);
  },

  methods: {
    ...mapActions({
      fetchSwapDetails: 'swapList/fetchSwapDetails',
      getSponsoredPropositions: 'propositions/getSponsoredPropositions',
      setShowContextualTutorial: 'tutorial/setShowContextualTutorial',
      setGlobalModal: 'ui/setGlobalModal',
      setEscClose: 'tutorial/setEscClose',
      removeDisableScroll: 'ui/removeDisableScroll',
      markInterest: 'interests/markInterest',
      setUnmarkPropositionNoInterest: 'swapList/setUnmarkPropositionNoInterest'
    }),

    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return rect.top + window.scrollY;
    },

    handleScroll() {
      if (this.$refs.progressBar && this.$refs.progressBar[0]) {
        const el = this.$refs.progressBar[0].$el;
        const pos = this.getOffset(el);

        if (window.top.scrollY >= pos && window.top.scrollY <= 900) {
          if (!el.classList.contains('fixed-mobile-progress-bar')) {
            el.classList.toggle('fixed-mobile-progress-bar', true);
          }
        } else {
          if (window.top.scrollY <= 200) {
            el.classList.toggle('fixed-mobile-progress-bar', false);
          }
        }
      }
    },

    onScroll() {
      let doScoll;

      window.onscroll = () => {
        clearTimeout(doScoll);
        doScoll = setTimeout(this.handleScroll, 100);
      };
    },

    handleClickOutsideProgress() {
      if (this.allowClickOutside) {
        this.handleCloseTooltip();
      }
    },

    handleTooltipCallback() {
      // allow click outside and esc close
      this.allowClickOutside = true;
      this.setEscClose(true);
      this.tooltipTimeout = setTimeout(() => {
        this.handleCloseTooltip();
      }, this.tooltipTimeoutMs);
    },

    handleCloseTooltip() {
      if (this.tutActiveStep === TUTORIAL_STEPS_TYPES.CONTINUE_INTEREST) {
        this.removeDisableScroll('ContextualTutModal');
        this.setShowContextualTutorial(false);
        this.allowClickOutside = false;
        this.setEscClose(false);
      }
    },

    handleShowSwapDetails({ propositionId, type }) {
      this.fetchSwapDetails({ propositionId, type });
    },

    handleNoInterest(propositionId) {
      this.reverseIsShown = true;
      setTimeout(() => {
        this.$store.dispatch('swapList/markPropositionForNoInterest', {
          targetPropositionId: propositionId,
          interested: false,
          where: 'swap-list',
          tutorialStarted: this.tutStatus === TUTORIAL_STATUS_TYPES.STARTED
        });
        this.reverseIsShown = false;
      }, 400);
    },

    handleReverseNoInterestMark(propositionId) {
      if (this.setToTrueAfterReverseClick) {
        this.markInterest({
          targetPropositionId: propositionId,
          interested: true,
          where: 'swap-list'
        });
      } else {
        this.markInterest({
          targetPropositionId: propositionId,
          skipRequest: true,
          interested: null,
          where: 'swap-list'
        });
      }

      this.setUnmarkPropositionNoInterest(propositionId.toString());
    },

    isPropositionNotInterested(propositionId) {
      return this.noInterestMarkedIds.includes(propositionId.toString());
    },

    isFirstPropositionNotInterested(swap, index) {
      if (this.isPropositionNotInterested(swap.propositionId.toString())) {
        for (let i = 0; i < index; i++) {
          if (
            this.swapList[i].propositionId == swap.propositionId &&
            this.swapList[i].details.type != swap.details.type
          ) {
            return false;
          }
        }
        return true;
      }

      return false;
    },

    setCardHeight(id) {
      const element = document.getElementById(id);
      if (element && element.offsetHeight) {
        let height = element.offsetHeight + 'px';
        this.dynamicHeight = height;
        return height;
      }
      return null;
    },

    updateAllCardHeights() {
      this.$nextTick(() => {
        this.swapList.forEach(swap => {
          if (swap) {
            const propositionId = swap.propositionId.toString();
            const propositionType = swap.details?.type || '';
            this.setCardHeight(propositionId + propositionType);
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-bar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 199;
  top: -5px;
  & .progress-bar-content {
    opacity: 0.85;
  }
  @media ($desktop) {
    position: static;
  }
}

.tooltip-container {
  position: absolute;
  z-index: 200;
  display: flex;
  top: 43px;
  max-width: 191px;
  font-size: 12px;
  font-weight: 700;
  flex-direction: column;
  align-items: center;
}

.reveal {
  margin-top: 10px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: slideUp 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.9s;
  border-radius: 10px;
  z-index: 200;
}

.reveal-progress {
  position: relative;
  z-index: 3000;
}

.swaps-container {
  position: relative;
}

.swap-items-wrapper {
  margin-bottom: 16px;
}

.next-button {
  margin-top: 10px;
  font-size: 12px;
  color: #409fff;
  background-color: #ffffff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sponsored-link {
  font-size: 0.8rem;
  color: $transfer-blue;
  text-decoration: underline;
  cursor: pointer;
}

.sponsored-propositions {
  @media ($mobile) {
    margin-left: -10px;
  }
}

.pulse-animation {
  transform: scale(1);
  animation: pulse 0.5s infinite;
}

@media ($mobile) {
  .reveal {
    top: -50px;
    position: relative;
    z-index: 200;
  }
  .fixed-mobile-progress-bar {
    position: fixed;
    animation: slideInProgressBar 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-delay: 0.2s;
    top: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slideInProgressBar {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(20px);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
  100% {
    opacity: 0.95;
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(20px);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="desktop-header">
    <div class="navbar">
      <Logo />

      <span v-if="debugText" class="color-warning">
        {{ debugText }}
      </span>

      <MenuItems
        :user-avatar="user.avatar"
        :notifications="notifications"
        :is-authenticated="isAuthenticated"
      />
    </div>
    <PopupMenu v-if="menuOpen" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isDebug } from '../../utils/debug';
import Logo from './Logo';
import MenuItems from './MenuItems';
import PopupMenu from './PopupMenu';

export default {
  name: 'DesktopHeader',

  components: {
    Logo,
    MenuItems,
    PopupMenu
  },

  data() {
    return {
      debugText: null
    };
  },

  computed: {
    ...mapGetters({
      menuOpen: 'menu/isMenuOpen',
      ready: 'app/ready',
      isAuthenticated: 'app/isAuthenticated',
      user: 'app/user',
      notifications: 'menu/getNotifications'
    })
  },

  created() {
    if (isDebug()) {
      const t = setInterval(() => {
        if (this.ready) {
          clearInterval(t);
          this.debugText = 'DEBUG';
        }
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.desktop-header {
  position: fixed;
  height: $desktop-menu-height;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);
  z-index: 99;
  transition: top 0.2s ease-in-out;
}

.navbar {
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-between;
  max-width: 2500px;
  padding: 0 $navbar-padding;
  width: 100%;
  user-select: none;

  @media (max-width: 1100px) {
    padding: 0 15px;
  }

  .color-warning {
    font-weight: bold;
    color: red;
  }
}
</style>
